import React from "react";
import { SlovHeader } from "./slovHeader";
import { useEffect, useState, useRef } from "react";
export const SlovVoiceScreen = (props) =>
{
    const refName = useRef(null);
    useEffect(() => {
        const elementName = refName.current;
        props.CBcolor("#545454");
    }, []);
    const [slovtext, setslovtext] = useState("");
    
    return(
    <div id="game">
    <SlovHeader/> 

<img src = {props.PColors != undefined ? "https://cyberbox.trgu.ru/public/avatars/prot/PlayerMain" + (props.PColors + 1) +".png": "Аватар загружается\n" }></img><React.Fragment><br/></React.Fragment>
<div className ="divtext_slov"><b>{props.prName}</b></div><React.Fragment><br/></React.Fragment>
<div className ="divtext_slov"><b>{props.slovGamePoints}</b> очков</div><React.Fragment><br/></React.Fragment>
<p>Вы записываете <font color={props.ColorString}><b>{props.TextString2}</b></font> слово!</p><React.Fragment><br/></React.Fragment>
<input className="FormInput" ref={refName} placeholder="Сообщение" onChange={(e) => setslovtext(e.target.value)} onKeyDown={(e) =>{if(e.key === "Enter"){ props.sendMessage("sendtoserver|slov\\addtext\\"+props.TextString+"\\"+slovtext); refName.current.value = "";}}}></input><React.Fragment><br/></React.Fragment>
<button type="button"  onClick={(e) =>{props.sendMessage("sendtoserver|slov\\addtext\\"+props.TextString+"\\"+slovtext); console.log(slovtext); const elementName = refName.current; elementName.value = "";}}>Отправить</button><React.Fragment><br/></React.Fragment>
    </div>
    );
}