import React, { useEffect } from "react";
import { SlovHeader } from "./KCATSNIGHT_slovHeader";

export const KCATSNIGHT_slovkcatsfibbytruthScreen = (props) => {
    useEffect(() => {
        props.CBcolor("#777777");
    }, []);

    const imageUrl = `https://cyberbox.trgu.ru/public/fibby/${props.TextString}`;

    const containerStyle = {
        width: "60%",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
    };

    const textStyle = {
        marginBottom: "20px",
        fontSize: "18px",
        color: "#333",
    };

    const imageStyle = {
        width: "100%",
        height: "auto",
        maxHeight: "400px",
        marginBottom: "20px",
        objectFit: "contain",
    };

    return (
        <div id="game">
            <SlovHeader/>
            <div style={containerStyle}>
                <div style={textStyle}>
                    Ты рассказываешь <font color='#1DAF13'>ПРАВДУ</font>, а значит описывай текущую картинку другим и не ври про её содержание!
                </div>
                <img src={imageUrl} alt="Fibby Image" style={imageStyle} />
                <div style={textStyle}>
                    Чтобы заработать очки нужно вынудить игроков подумать, что ты говоришь ложь и не видишь картинки. Но по правилам игры ты не имеешь права врать по содержанию картинки, поэтому <b>описывай картинку и отвечай на все вопросы честно</b>.
                </div>
            </div>
        </div>
    );
}
