import React, {useState, useEffect} from 'react';
//import useWebSocket from 'react-use-websocket';
import useWebSocket from 'react-use-websocket';
//import io from 'socket.io-client';

import { ProtMainGame } from './Protestation/protMain';
import { SlovMainGame } from './Slovoblud/slovMain';


// PROTESTATION SCREENS ------------------------------------------------------------------------------
import { TestPanel } from './testpanel';
import { LobbyScreen } from './GameElements/lobbyscreen';
/*
import { ProtLobbyScreen } from './Protestation/protLobby';
import { ProtLobbyTutorialScreen } from './Protestation/protLobby_tutorial';
import { ProtThemeChoose } from './Protestation/protLobby_themes';
import { ProtLectorScreen } from './Protestation/protLobby_lector';
import { ProtPlayerScreen } from './Protestation/protPlayerScreen';
import { ProtJudgeAwait } from './Protestation/protJudge_wait';
import { ProtJudgeChoose } from './Protestation/protJudge_choose';
import { ProtPlayerAward } from './Protestation/protPlayerAward';
*/
// ---------------------------------------------------------------------------------------------------



 const socketUrl = 'wss://cyberbox.trgu.ru/api/';
const Gameversion = "1.374";


export const MainGame = (props) =>
{
  //console.log(props.PlayerName);//clientjoin|room_code|client_id
  const [Pingt, setPingt] = useState("");
  const [WSMsg, setWSMsg] = useState("");
  const [GState, setGState] = useState(1);
  const [returnstring, setreturnstring] = useState("");//
  const [Gamepoints, setGamepoints] = useState(0);
  const [GlobalGamepoints, setGlobalGamepoints] = useState(0);


  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(socketUrl, {
    onOpen: () => {sendMessage("clientjoin|" + props.RCode + "|" + props.PID)},
    share: false,
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });

useEffect(() => {
console.log(Gameversion);
if(props.isDebug) setGState(0);
}, [props.isDebug]);
useEffect(() => {
  if(lastMessage != null)
  {
  //console.log(lastMessage.data);
  setWSMsg(lastMessage.data);
  //handleWSmessage()
  } 
}, [lastMessage]);
useEffect(() => {
  console.log(WSMsg);
  if(WSMsg !== undefined)
  if(!props.isDebug) 
  {
  if (WSMsg.startsWith("main"))
  {
    handleMainMessage(WSMsg);
  }
  else if (WSMsg.startsWith("prot"))
  {
    setGState(2);
  }
  else if (WSMsg.startsWith("cbx"))
  {
    handleCbxMessage(WSMsg);
  }
  else if (WSMsg.startsWith("slov"))
  {
    setGState(3);
  }
  }
  }, [WSMsg]);

/*
const handleWSmessage = (Wmsg) =>
{
  console.log(Wmsg);
  if(Wmsg !== undefined)
  if(!props.isDebug) 
  {
  if (Wmsg.startsWith("main"))
  {
    handleMainMessage(Wmsg);
  }
  else if (Wmsg.startsWith("prot"))
  {
    setGState(2);
  }
  else if (Wmsg.startsWith("cbx"))
  {
    handleCbxMessage(Wmsg);
  }
  }
}
*/

useEffect(() => {
  if(props.PColor !== undefined)
  {
  
  } 
}, [props.PColor]);




const handleMainMessage = (Wmsg) =>
{
  var msg = Wmsg.split("\\");
  console.log(msg[1])
  setGState(1);
  switch (msg[1])
  {
    case "MainLobby":
    props.ChangeBColor("#000000"); 
    setGState(1);
    break;
  }
}

const handleCbxMessage = (Wmsg) =>
{
  var msg = Wmsg.split("\\");
  setGState(1);
  console.log(msg[1])
  switch (msg[1])
  {
    case "setGlobalPoints":
      setGlobalGamepoints(msg[2]);
    break;
  }
}

const sendbeep = () =>
{
  sendMessage("sendtoserver|" + Pingt);
}
/*
GameStates 
0 - (Кибербокс) Экран с проверкой
1 - (Кибербокс) Обычное лобби
|| Протестейшн
2 - (Протестейшн) Лобби протестейшна
3 - (Протестейшн) Туториал протестейшна
4 - (Протестейшн) Выбор темы протестейшна
5 - (Протестейшн) Экран лектора протестейшна
6 - (Протестейшн) Экран игрока протестейшна
7 - (Протестейшн) Экран судьи протестейшна
8 - (Протестейшн) Экран принятия протеста
9 - (Протестейшн) Экран оценки судьи
*/
useEffect(() => {
  switch (GState)
  {
  case 0:
  setreturnstring(<TestPanel PColors = {props.PColor}  fsetPingt = {setPingt} prName = {props.PName} prCode = {props.RCode} sendMessageW = {sendMessage} CBcolor = {props.ChangeBColor} LastMsg = {WSMsg}/>);
  break;
  case 1:
    setreturnstring(<LobbyScreen PColors = {props.PColor}  fsetPingt = {setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {sendMessage} GlobalGamepoints = {GlobalGamepoints}/>);
  break;
  case 2:
    setreturnstring(<ProtMainGame PColor = {props.PColor}  setPingt = {setPingt} PName = {props.PName} RCode = {props.RCode}  ChangeBColor = {props.ChangeBColor} sendMessage = {sendMessage} WSMessage = {WSMsg}/>);
  break;
  case 3:
    setreturnstring(<SlovMainGame PColor = {props.PColor}  setPingt = {setPingt} PName = {props.PName} RCode = {props.RCode}  ChangeBColor = {props.ChangeBColor} sendMessage = {sendMessage} WSMessage = {WSMsg}  CBcolor = {props.ChangeBColor}/>);
  break;
  } 
}, [GState, WSMsg, GlobalGamepoints]);
return(
  returnstring
  );
}