import React, { useState, useEffect } from "react";
import { SlovHeader } from "./slovHeader";

export const SlovCellsScreen = (props) => {
    const [selectedButtons, setSelectedButtons] = useState(Array(9).fill(false));

    useEffect(() => {
        props.CBcolor("#545454");
    }, []);

    const handleButtonClick = (index) => {
        const newSelectedButtons = [...selectedButtons];
        newSelectedButtons[index] = !newSelectedButtons[index];
        setSelectedButtons(newSelectedButtons);
        const action = newSelectedButtons[index] ? "select" : "deselect";
        props.sendMessage(`sendtoserver|slov\\buttonchosen\\${index + 1}\\${action}`);
        console.log(index + 1, action);
    };

    const handleConfirmClick = () => {
        props.sendMessage(`sendtoserver|slov\\buttonchosen\\confirm\\all`);
        console.log("confirm all");
    };

    const images = props.TextString.split(',').slice(0, 9); // Ограничиваем количество изображений до 9

    return (
        <div id="game">
            <SlovHeader /> 
            <img src={props.PColors !== undefined ? `https://cyberbox.trgu.ru/public/avatars/prot/PlayerMain${props.PColors + 1}.png` : "Аватар загружается\n"} alt="Player Avatar" />
            <React.Fragment><br/></React.Fragment>
            <div className="divtext_slov"><b>{props.prName}</b></div>
            <React.Fragment><br/></React.Fragment>
            <div className="divtext_slov"><b>{props.slovGamePoints}</b> очков</div>
            <React.Fragment><br/></React.Fragment>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px', justifyItems: 'center' }}>
                {images.map((img, index) => (
                    <button
                        key={index}
                        type="button"
                        onClick={() => handleButtonClick(index)}
                        style={{ 
                            width: '80px', 
                            height: '80px', 
                            padding: 0, 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center', 
                            border: selectedButtons[index] ? '2px solid #078BEA' : 'none' 
                        }} // Размер кнопок и центрирование изображения внутри
                    >
                        <img 
                            src={`https://cyberbox.trgu.ru/public/cells/${img.trim()}`} 
                            alt={`Button ${index + 1}`} 
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }} // Растягиваем изображение по высоте и обрезаем по ширине
                        />
                    </button>
                ))}
            </div>
            <button 
                type="button" 
                onClick={handleConfirmClick} 
                style={{ marginTop: '20px', padding: '10px 20px', backgroundColor: '#078BEA', color: 'white', border: 'none', borderRadius: '5px' }}
            >
                Отправить
            </button>
        </div>
    );
};
