import React, { useEffect } from "react";
import { SlovHeader } from "./KCATSNIGHT_slovHeader";

export const KCATSNIGHT_slovkcatsfibbypickScreen = (props) => {
    useEffect(() => {
        props.CBcolor("#777777");
    }, []);

    const buttonStyle = {
        width: "100%",
        padding: "15px",
        fontSize: "18px",
        textAlign: "center",
        marginBottom: "10px",
        cursor: "pointer",
        backgroundColor: "#4CAF50",
        color: "white",
        border: "none",
        borderRadius: "5px",
    };

    const containerStyle = {
        width: "60%",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    };

    const textStyle = {
        marginBottom: "20px",
        fontSize: "20px",
        textAlign: "center",
        color: "#333",
    };

    return (
        <div id="game">
            <SlovHeader/>
            <div style={containerStyle}>
                <div style={textStyle}>
                    Попробуйте определить описывает ли {props.TextString} настоящую картинку или придумывает ложь на ходу.
                </div>
                <button
                    type="button"
                    style={buttonStyle}
                    onClick={() => props.sendMessage("sendtoserver|slov\\buttonchosen\\think_truth")}
                >
                    {props.TextString} говорит правду
                </button>
                <button
                    type="button"
                    style={buttonStyle}
                    onClick={() => props.sendMessage("sendtoserver|slov\\buttonchosen\\think_lie")}
                >
                    {props.TextString} нагло врёт
                </button>
                <button
                    type="button"
                    style={buttonStyle}
                    onClick={() => props.sendMessage("sendtoserver|slov\\buttonchosen\\think_ask")}
                >
                    Я хочу задать вопрос игроку
                </button>
            </div>
        </div>
    );
}
