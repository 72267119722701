import React, { useState, useEffect } from "react";
import { SlovHeader } from "./slovHeader";

export const SlovRowsScreen = (props) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        props.CBcolor("#545454");
    }, []);

    const getImageSrc = (index) => {
        const indices = props.TextString2.split(",");
        const baseIndex = indices[index];
        let charType;
        switch (props.TextString) {
            case "top":
                charType = "1";
                break;
            case "mid":
                charType = "2";
                break;
            case "bot":
                charType = "3";
                break;
            default:
                charType = "1";
        }
        return `https://cyberbox.trgu.ru/public/chars/char${baseIndex}_${charType}.png`;
    };

    const handleLeftClick = () => {
        const indices = props.TextString2.split(",");
        const newIndex = (currentIndex - 1 + indices.length) % indices.length;
        setCurrentIndex(newIndex);
        props.sendMessage(`sendtoserver|slov\\buttonchosen\\${props.TextString}\\${indices[newIndex]}`);
    };

    const handleRightClick = () => {
        const indices = props.TextString2.split(",");
        const newIndex = (currentIndex + 1) % indices.length;
        setCurrentIndex(newIndex);
        props.sendMessage(`sendtoserver|slov\\buttonchosen\\${props.TextString}\\${indices[newIndex]}`);
    };

    return (
        <div id="game">
            <SlovHeader />
            <img src={props.PColors != undefined ? `https://cyberbox.trgu.ru/public/avatars/prot/PlayerMain${props.PColors + 1}.png` : "Аватар загружается\n"} alt="Player Avatar" style={{ width: '100px' }}></img>
            <React.Fragment><br/></React.Fragment>
            <div className="divtext_slov"><b>{props.prName}</b></div>
            <React.Fragment><br/></React.Fragment>
            <div className="divtext_slov"><b>{props.slovGamePoints}</b> очков</div>
            <React.Fragment><br/></React.Fragment>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <button style={{ margin: '0 10px', width: '7%' }} onClick={handleLeftClick}>◄</button>
                <img src={getImageSrc(currentIndex)} alt="Character" style={{ margin: '0 10px', width: '80%' }} />
                <button style={{ margin: '0 10px', width: '7%' }} onClick={handleRightClick}>►</button>
            </div>
        </div>
    );
};
