import React, { useEffect } from "react";
import { SlovHeader } from "./KCATSNIGHT_slovHeader";

export const KCATSNIGHT_slovMemesVoteScreen = (props) => {
    useEffect(() => {
        props.CBcolor("#777777");
    }, []);

    const buttonStyle = {
        width: "45%",
        padding: "20px",
        fontSize: "16px",
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    };

    const buttonContainerStyle = {
        display: "flex",
        justifyContent: "space-between",
    };

    const formatText = (text) => {
        return text.split('[p]').map((str, index) => <div key={index}>{str}</div>);
    };

    return (
        <div id="game">
            <SlovHeader/>
            <img src={props.PColors !== undefined ? "https://cyberbox.trgu.ru/public/avatars/prot/PlayerMain" + (props.PColors + 1) + ".png" : "Аватар загружается\n"} alt="Player Avatar"></img>
            <React.Fragment><br/></React.Fragment>
            <div className="divtext_slov"><b>{props.prName}</b></div>
            <React.Fragment><br/></React.Fragment>
            <div className="divtext_slov"><b>{props.slovGamePoints}</b> очков</div>
            <React.Fragment><br/></React.Fragment>
            <div style={buttonContainerStyle}>
                <button type="button" style={buttonStyle} onClick={(e) => {props.sendMessage("sendtoserver|slov\\buttonchosen\\left"); console.log("Левая кнопка");}}>
                    {formatText(props.TextString)}
                </button>
                <button type="button" style={buttonStyle} onClick={(e) => {props.sendMessage("sendtoserver|slov\\buttonchosen\\right"); console.log("Правая кнопка");}}>
                    {formatText(props.TextString2)}
                </button>
            </div>
        </div>
    );
}
