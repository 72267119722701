import React, { useEffect } from "react";
import { SlovHeader } from "./KCATSNIGHT_slovHeader";

export const KCATSNIGHT_slovButtonScreen = (props) => {
    useEffect(() => {
        props.CBcolor("#777777");
    }, []);

    return (
        <div id="game">
            <SlovHeader/>
            <img src={props.PColors !== undefined ? "https://cyberbox.trgu.ru/public/avatars/prot/PlayerMain" + (props.PColors + 1) + ".png" : "Аватар загружается\n"} alt="Player Avatar"></img>
            <React.Fragment><br/></React.Fragment>
            <div className="divtext_slov"><b>{props.prName}</b></div>
            <React.Fragment><br/><br/><br/></React.Fragment>
            <div id = "glassbutton"><button type="button" onClick={(e) =>{props.sendMessage("sendtoserver|slov\\buttonchosen\\kcatsanswer"); console.log("hit");}}>Ответить!</button></div><React.Fragment><br/></React.Fragment>
        </div>
    );
}
