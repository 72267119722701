import React, { useEffect } from "react";
import { SlovHeader } from "./KCATSNIGHT_slovHeader";

export const KCATSNIGHT_slovkcatsfibbylieScreen = (props) => {
    useEffect(() => {
        props.CBcolor("#777777");
    }, []);

    const textStyle = {
        marginBottom: "20px",
        fontSize: "18px",
        textAlign: "center",
        color: "#333",
    };

    const containerStyle = {
        width: "60%",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    };

    return (
        <div id="game">
            <SlovHeader/>
            <div style={containerStyle}>
                <div style={textStyle}>
                    Ты рассказываешь <font color='#AD000B'>ЛОЖЬ</font>.
                </div>
                <div style={textStyle}>
                    Сделай вид, что перед тобой есть отчётливая картинка и начинай её детально описывать другим игрокам, чтобы они поверили, что у тебя реально есть картинка на экране.
                </div>
                <div style={textStyle}>
                    Старайся не облажаться и не забывай отвечать на вопросы игроков.
                </div>
            </div>
        </div>
    );
}
