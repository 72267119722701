import React, { useState, useEffect } from "react";
import { SlovHeader } from "./slovHeader";

export const SlovLotteryScreen = (props) => {
    const [disabledButtons, setDisabledButtons] = useState(Array(9).fill(false));

    useEffect(() => {
        props.CBcolor("#545454");
    }, []);

    const handleButtonClick = (index) => {
        const newDisabledButtons = [...disabledButtons];
        newDisabledButtons[index] = true;
        setDisabledButtons(newDisabledButtons);
        props.sendMessage(`sendtoserver|slov\\buttonchosen\\${index + 1}`);
        console.log(index + 1);
    };

    return (
        <div id="game">
            <SlovHeader /> 
            <img src={props.PColors != undefined ? "https://cyberbox.trgu.ru/public/avatars/prot/PlayerMain" + (props.PColors + 1) + ".png" : "Аватар загружается\n"} alt="Player Avatar"></img>
            <React.Fragment><br/></React.Fragment>
            <div className="divtext_slov"><b>{props.prName}</b></div>
            <React.Fragment><br/></React.Fragment>
            <div className="divtext_slov"><b>{props.slovGamePoints}</b> очков</div>
            <React.Fragment><br/></React.Fragment>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px', justifyItems: 'center' }}>
                {Array.from({ length: 9 }, (_, index) => (
                    <button
                        key={index}
                        type="button"
                        onClick={() => handleButtonClick(index)}
                        disabled={disabledButtons[index]}
                        style={{ width: '80px',  height: '80px', padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }} // Размер кнопок и центрирование изображения внутри
                    >
                        <img 
                            src="https://cyberbox.trgu.ru/public/locked.png" 
                            alt={`Button ${index + 1}`} 
                            style={{ width: '60px', height: '60px' }} // Размер изображения
                        />
                    </button>
                ))}
            </div>
        </div>
    );
};
