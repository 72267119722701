import React, { useEffect } from "react";
import { SlovHeader } from "./KCATSNIGHT_slovHeader";

export const KCATSNIGHT_SlovWaitingScreen = (props) => {
    useEffect(() => {
        props.CBcolor("#777777");
    }, []);

    return (
        <div id="game">
            <SlovHeader />
            <img src={props.PColors !== undefined ? `https://cyberbox.trgu.ru/public/avatars/prot/PlayerMain${props.PColors + 1}.png` : "Аватар загружается"} alt="Avatar" />
            <React.Fragment><br /></React.Fragment>
            <div className="divtext_slov"><b>{props.prName}</b></div>
            <React.Fragment><br /></React.Fragment>
            <div className="divtext_slov"><b>{props.slovGamePoints}</b> очков</div>
            <React.Fragment><br /></React.Fragment>
            <div className="divtext_slov"><b>Ожидайте...</b></div>
        </div>
    );
}
