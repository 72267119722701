import React from "react";
import { SlovHeader } from "./KCATSNIGHT_slovHeader";
import { useEffect, useState, useRef } from "react";

export const KCATSNIGHT_slovTypeScreen = (props) => {
    const refName = useRef(null);
    useEffect(() => {
        const elementName = refName.current;
        props.CBcolor("#777777");
    }, []);

    const [slovtext, setslovtext] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const handleChange = (e) => {
        const value = e.target.value;
        if (value.length <= 5) {
            setslovtext(value);
            setIsButtonDisabled(value.length === 0);
        }
    };

    return (
        <div id="game">
            <SlovHeader />

            <img src={props.PColors != undefined ? "https://cyberbox.trgu.ru/public/avatars/prot/PlayerMain" + (props.PColors + 1) + ".png" : "Аватар загружается\n"} alt="Avatar"></img>
            <React.Fragment><br /></React.Fragment>
            <div className="divtext_slov"><b>{props.prName}</b></div>
            <React.Fragment><br /></React.Fragment>
            <div className="divtext_slov"><b>{props.slovGamePoints}</b> очков</div>
            <React.Fragment><br /></React.Fragment>
            <input 
                className="FormInput" 
                ref={refName} 
                placeholder="Сообщение" 
                value={slovtext} 
                onChange={handleChange} 
                onKeyDown={(e) => {
                    if (e.key === "Enter" && slovtext.length >= 1 && slovtext.length <= 5) {
                        props.sendMessage("sendtoserver|slov\\addtext\\" + slovtext);
                        setslovtext("");
                        setIsButtonDisabled(true);
                    }
                }}
            />
            <React.Fragment><br /></React.Fragment>
            <button 
                type="button"  
                onClick={() => {
                    props.sendMessage("sendtoserver|slov\\addtext\\" + slovtext);
                    setslovtext("");
                    setIsButtonDisabled(true);
                }} 
                disabled={isButtonDisabled}
            >
                Отправить
            </button>
            <React.Fragment><br /></React.Fragment>
        </div>
    );
};
