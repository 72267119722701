import React from "react";
import { SlovHeader } from "./KCATSNIGHT_slovHeader";
import { useEffect, useState, useRef } from "react";

export const KCATSNIGHT_SlovMemesSend2Screen = (props) => {
    const refName = useRef(null);

    useEffect(() => {
        const elementName = refName.current;
        props.CBcolor("#777777");
    }, []);

    const [slovtext, setslovtext] = useState("");
    const [charCount, setCharCount] = useState(70);
    const [lineCount, setLineCount] = useState(1);

    const handleInputChange = (e) => {
        const inputText = e.target.value;
        const newLineCount = (inputText.match(/\n/g) || []).length + 1;

        if (inputText.length <= 70 && newLineCount <= 3) {
            setslovtext(inputText);
            setCharCount(70 - inputText.length);
            setLineCount(newLineCount);
        }
    };

    const isInputEmpty = slovtext.trim() === "";

    return (
        <div id="game">
            <SlovHeader />

            <img src={props.PColors != undefined ? "https://cyberbox.trgu.ru/public/avatars/prot/PlayerMain" + (props.PColors + 1) + ".png" : "Аватар загружается\n"}></img>
            <React.Fragment><br/></React.Fragment>
            <div className="divtext_slov"><b>{props.prName}</b></div>
            <React.Fragment><br/></React.Fragment>
            <div className="divtext_slov"><b>{props.slovGamePoints}</b> очков</div>
            <React.Fragment><br/></React.Fragment>
            <div className="divtext_slov">Придумайте фразу, которая выглядела бы забавной, стоя над ответом от mirkoi. Например:</div>
            <div className="divtext_slov">"Миркой, когда зарплата?"</div>
            <React.Fragment><br/></React.Fragment>
            
            <React.Fragment><br/></React.Fragment>
            <div className="divtext_slov"><b>Задание ({props.TextString2} из 2)</b></div>
            <p style={{ fontSize: '18px', color: 'black' }}>Вам достался скриншот с этой фразой: {props.TextString}</p>
            <React.Fragment><br/></React.Fragment>

            <textarea autocomplete="off" className="FormInput" style={{ width: '80%', height: '4em' }} ref={refName} placeholder="Сообщение" maxLength="70" value={slovtext} onChange={handleInputChange}></textarea>
            <div className="charCount" style={{ color: 'black' }}>Осталось символов: {charCount}, строк: {lineCount} из 3</div>
            <React.Fragment><br/></React.Fragment>
            <button type="button" disabled={isInputEmpty} onClick={(e) => {
                if (slovtext.length <= 70 && lineCount <= 3) {
                    props.sendMessage(`sendtoserver|slov\\addtext\\${props.TextString2}\\` + slovtext);
                    console.log(slovtext);
                    setslovtext("");
                    setCharCount(70);
                    setLineCount(1);
                }
            }}>Отправить</button>
            <React.Fragment><br/></React.Fragment>
        </div>
    );
}
