import React from "react";
import { SlovHeader } from "./slovHeader";
import { useEffect } from "react";
export const SlovColorcodeScreen = (props) =>
{
    useEffect(() => {
        props.CBcolor("#545454");
    }, []);
    return(
    <div id="game">
    <SlovHeader/> 

<img src = {props.PColors != undefined ? "https://cyberbox.trgu.ru/public/avatars/prot/PlayerMain" + (props.PColors + 1) +".png": "Аватар загружается\n" }></img><React.Fragment><br/></React.Fragment>
<div className ="divtext_slov"><b>{props.prName}</b></div><React.Fragment><br/></React.Fragment>
<div className ="divtext_slov"><b>{props.slovGamePoints}</b> очков</div><React.Fragment><br/></React.Fragment>
<button type="button" onClick={(e) =>{props.sendMessage("sendtoserver|slov\\buttonchosen\\red"); console.log("red");}}><img src="https://cyberbox.trgu.ru/public/colorcode/red0.png"></img></button><React.Fragment><br/></React.Fragment>
<button type="button"  onClick={(e) =>{props.sendMessage("sendtoserver|slov\\buttonchosen\\yellow"); console.log("yellow");}}><img src="https://cyberbox.trgu.ru/public/colorcode/yellow0.png"></img></button><React.Fragment><br/></React.Fragment>
<button type="button"  onClick={(e) =>{props.sendMessage("sendtoserver|slov\\buttonchosen\\green"); console.log("green");}}><img src="https://cyberbox.trgu.ru/public/colorcode/green0.png"></img></button><React.Fragment><br/></React.Fragment>
<button type="button"  onClick={(e) =>{props.sendMessage("sendtoserver|slov\\buttonchosen\\blue"); console.log("blue");}}><img src="https://cyberbox.trgu.ru/public/colorcode/blue0.png"></img></button><React.Fragment><br/></React.Fragment>
    </div>
    );
}