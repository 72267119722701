import React from "react";
import { SlovHeader } from "./slovHeader";
import { useEffect } from "react";
export const SlovTriviaScreen = (props) =>
{
    useEffect(() => {
        props.CBcolor("#545454");
    }, []);
    return(
    <div id="game">
    <SlovHeader/> 

<img src = {props.PColors != undefined ? "https://cyberbox.trgu.ru/public/avatars/prot/PlayerMain" + (props.PColors + 1) +".png": "Аватар загружается\n" }></img><React.Fragment><br/></React.Fragment>
<div className ="divtext_slov"><b>{props.prName}</b></div><React.Fragment><br/></React.Fragment>
<div className ="divtext_slov"><b>{props.slovGamePoints}</b> очков</div><React.Fragment><br/></React.Fragment>
<button type="button"  onClick={(e) =>{props.sendMessage("sendtoserver|slov\\buttonchosen\\A"); console.log("Вариант A");}}>Вариант A</button><React.Fragment><br/></React.Fragment>
<button type="button"  onClick={(e) =>{props.sendMessage("sendtoserver|slov\\buttonchosen\\B"); console.log("Вариант B");}}>Вариант B</button><React.Fragment><br/></React.Fragment>
<button type="button"  onClick={(e) =>{props.sendMessage("sendtoserver|slov\\buttonchosen\\C"); console.log("Вариант C");}}>Вариант C</button><React.Fragment><br/></React.Fragment>
<button type="button"  onClick={(e) =>{props.sendMessage("sendtoserver|slov\\buttonchosen\\D"); console.log("Вариант D");}}>Вариант D</button><React.Fragment><br/></React.Fragment>
    </div>
    );
}